const IMAGES = {
  logo: require("./images/logo.svg").default,
  computer: require("./images/image-computer.png"),
  devices: require("./images/image-devices.png"),
  blacklist: require("./images/icon-blacklist.svg").default,
  text: require("./images/icon-text.svg").default,
  preview: require("./images/icon-preview.svg").default,
  snekti: require("./images/logo-snekti.png"),
  snektiWhite: require("./images/logo-snekti-putih.png"),
  google: require("./images/logo-google.png"),
  ibm: require("./images/logo-ibm.png"),
  microsoft: require("./images/logo-microsoft.png"),
  hp: require("./images/logo-hp.png"),
  vector: require("./images/logo-vector-graphics.png"),
  narasumber: require("./images/narasumber.png"),
  close: require("./images/close.png"),
  facebook: require("./images/icon-facebook.svg").default,
  twitter: require("./images/icon-twitter.svg").default,
  instragram: require("./images/icon-instagram.svg").default,
  plnip: require("./images/ip-logo.png"),
  plnepi: require("./images/epi-logo.png"),
  puslitbang: require("./images/puslitbang-logo.png"),
  fj: require("./images/fj-logo.png"),
  ujpk: require("./images/ujpk-logo.png"),
  sinta: require("./images/sinta-logo.png"),
  googlesc: require("./images/Google Scholar-logo.png"),
  kemdikbud: require("./images/kemdikbud.png"),
  itpln: require("./images/itpln-logo.png"),
  ypk: require("./images/ypkpln-logo.png"),
  copy: require("./images/copy.png"),
  bni: require("./images/BNI-logo.png"),
  techno: require("./images/techno.png"),
  itplnmedia: require("./images/logo itpln media.png"),
  download: require("./images/download.png"),
};

export default IMAGES;
