import React from "react";
import IMAGES from "../Assets/Images";

function Agents() {
  return (
    <>
      <section className="agents agents-wrapper">
        <div className="agents__items">
          <img className="resize" src={IMAGES.narasumber} alt="narasumber" />
        </div>
      </section>
    </>
  );
}

export default Agents;
