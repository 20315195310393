import React from "react";
import Buttons from "../Components/Buttons";
import IMAGES from "../Assets/Images";
function Hero() {
  return (
    <>
      <header className="header">
        <div style={{ marginTop: "20px" }}>
          <h2>TERIMAKASIH SUDAH MENGIKUTI ACARA</h2>
        </div>
        <div className="header__img">
          <img className="hero-image" src={IMAGES.snekti} alt="logo" />
        </div>
        {/* <h1 className="hero-title">SNEKTI 2023</h1> */}
        <p className="desktop__text" style={{ marginBottom: "10px" }}>
          Inovasi Transformasi Digital di Bidang Ketenagalistrikan Untuk
          Pengembangan Green Industry
        </p>
        <div className="date">
          <p className="date-text">Rabu 13 Desember 2023</p>
        </div>
        <div
          onClick={() => {
            window.open(
              "https://snekti.jurnal-puslitbangpln.id/submit/index.php/",
              "_blank"
            );
          }}
        >
          <h1
            style={{
              border: "solid grey",
              borderRadius: "15px",
              cursor: "pointer",
              marginBottom: "20px",
            }}
          >
            Prosiding SNEKTI 2023
          </h1>
        </div>
        {/* <Buttons />
        <p style={{ margin: 0, fontWeight: 700 }}>Deadline : 6 Desember 2023</p> */}
        {/* <iframe
          width="660"
          height="415"
          src="https://www.youtube.com/embed/fHGVd6SqZmU?si=1FzTulNoGUFUR_DA"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          className="player"
        ></iframe> */}
      </header>
    </>
  );
}

export default Hero;
