import React from "react";

function Buttons() {
  return (
    <>
      <div
        className="buttons"
        onClick={() => {
          window.open(
            "https://snekti.jurnal-puslitbangpln.id/submit/index.php/snekti2023/login",
            "_blank"
          );
        }}
      >
        <a href="" style={{ marginBottom: 0 }}>
          Submit Your Paper
        </a>
      </div>
    </>
  );
}

export default Buttons;
