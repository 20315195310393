import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip, Button } from "reactstrap";
import IMAGES from "../Assets/Images";

function Fee() {
  const [text, setText] = useState("");
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const handleCopy = () => {
    setText("Copied to clipboard!");
    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 2000);
  };
  return (
    <>
      <section
        className="supercharge"
        style={{ paddingTop: "10px" }}
        id="payment"
      >
        <h2>Conference Fee</h2>
        <div className="supercharge_items">
          <div className="item">
            <h3>Pemakalah Dosen dan Umum</h3>
            <p>Rp. 500.000</p>
          </div>

          <div className="item">
            <h3>Pemakalah Mahasiswa (S1 / S2)</h3>
            <p>Rp. 250.000</p>
          </div>

          <div className="item">
            <h3>Non Pemakalah</h3>
            <p style={{ fontWeight: 700 }}>Gratis</p>
          </div>
        </div>
        {/* <h2>Payment</h2>
        <div className="item">
          <h4>Bank BSI</h4>

          <CopyToClipboard text="7027129042" onCopy={handleCopy}>
            <h4 id="tooltip" style={{ cursor: "pointer" }}>
              No Rekening 7027129042
            </h4>
          </CopyToClipboard>
          <Tooltip
            placement="bottom"
            isOpen={tooltipOpen}
            target="tooltip"
            style={{
              backgroundColor: "ButtonShadow",
              fontSize: "16px",
              padding: "3px",
              borderRadius: "5px",
            }}
          >
            Copied to clipboard !
          </Tooltip>
          <h4>a/n STT YAYASAN PLN</h4>
        </div> */}
      </section>
    </>
  );
}

export default Fee;
