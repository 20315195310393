import React, { useState } from "react";
import Hero from "./Page/Hero";
import Snippets from "./Page/Snippets";
import Access from "./Page/Access";
import Fee from "./Page/Fee";
import Agents from "./Page/Agents";
import Committe from "./Page/Committe";
import Footer from "./Page/Footer";
import IMAGES from "./Assets/Images";

import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
// import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Link } from "@mui/material";
import Sponsors from "./Page/Sponsors";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const WarningDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function App(args) {
  const [open, setOpen] = useState(true);
  const [warning, setWarning] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const closeWarning = () => {
    setWarning(false);
    window.open(
      "https://snekti.jurnal-puslitbangpln.id/submit/index.php/snekti2023/login",
      "_blank"
    );
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          style={{ fontSize: "24px" }}
        >
          Pengumuman
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 18,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <img
            src={IMAGES.close}
            alt=""
            style={{ margin: "0 2px", padding: 0, width: 24 }}
          />
        </IconButton>
        <DialogContent dividers style={{ padding: "50px 150px" }}>
          <Typography gutterBottom variant="h5" fontWeight={700}>
            SERTIFIKAT SNEKTI 2023
          </Typography>
          {/* https://drive.google.com/file/d/1H__gGK3Zurs7CSR-9cCMrNh6Gt0X7Q93/view?usp=sharing */}
          <Link href="https://itpln.id/sertifSNEKTI" target="_blank">
            <center>
              <img src={IMAGES.download} alt="" className="download" />
            </center>
          </Link>
        </DialogContent>

        {/* <DialogContent dividers>
          <Typography gutterBottom variant="h5" fontWeight={700}>
            LINK ZOOM NON PEMAKALAH
          </Typography>
          <Link href="https://itpln.id/SNEKTI2023" target="_blank">
            Join Zoom Meeting
          </Link>
        </DialogContent> */}

        {/* <DialogContent dividers>
          <Typography gutterBottom variant="h5" fontWeight={700}>
            GRUP PEMAKALAH SNEKTI 2023
          </Typography>
          <Typography variant="h5">
            Bagi yang sudah Register atau Submit Artikel SNEKTI 2023 silahkan
            Join Grup WA
          </Typography>
          <Link href="https://chat.whatsapp.com/FikfUtGLQaB9VioHPahAAA">
            Link Grup
          </Link>
        </DialogContent> */}
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <WarningDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={warning}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          style={{ fontSize: "24px" }}
        >
          Perhatian !
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeWarning}
          sx={{
            position: "absolute",
            right: 18,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <img
            src={IMAGES.close}
            alt=""
            style={{ margin: "0 2px", padding: 0, width: 24 }}
          />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom variant="h5">
            Mohon Diperhatikan Artikel Yang Disubmit Harus Sesuai Template Yang
            Kami Sediakan
          </Typography>
          <Link href="https://drive.google.com/file/d/1h6E8t2R4_euo_XWkX3x39BX2W9Q4qGvM/view">
            Link Template
          </Link>
        </DialogContent>

        <DialogActions>
          <Button onClick={closeWarning} variant="contained" color="error">
            Close
          </Button>
        </DialogActions>
      </WarningDialog>
      <nav className="navbar">
        <div className="logo">
          <img src={IMAGES.snektiWhite} alt="Logo" />
        </div>
        <ul className="nav-links">
          <li>
            <a
              href="https://drive.google.com/file/d/1h6E8t2R4_euo_XWkX3x39BX2W9Q4qGvM/view"
              target="_blank"
              rel="noreferrer"
            >
              Article Template
            </a>
          </li>

          <li>
            <a
              href="https://drive.google.com/file/d/19wsCyVQhEfDPReoHniKj9kEf2DOqkuxn/view"
              target="_blank"
              rel="noreferrer"
            >
              Download Flyer
            </a>
          </li>
          <li
            onClick={() => scrollToSection("payment")}
            style={{ cursor: "pointer" }}
          >
            <a rel="noreferrer">Payment</a>
          </li>
          <li
            onClick={() => scrollToSection("sponsors")}
            style={{ cursor: "pointer" }}
          >
            <a rel="noreferrer">Sponsors</a>
          </li>
          <li>
            <a
              onClick={() => {
                setWarning(true);
              }}
              style={{ cursor: "pointer" }}
            >
              Register
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                setWarning(true);
              }}
              style={{ cursor: "pointer" }}
            >
              Login
            </a>
          </li>
        </ul>
      </nav>
      <main className="main">
        <Hero />
        {/* <Snippets /> */}
        {/* <Access /> */}
        {/* <Supercharge /> */}
        <Agents />
        <Committe />
        <Fee />
        {/* <Action /> */}
        <Sponsors />
      </main>
      <footer className="footer">
        <Footer />
      </footer>
    </>
  );
}

export default App;
