import React from "react";
import IMAGES from "../Assets/Images";

function Committe() {
  return (
    <>
      <section className="supercharge">
        <h2>Steering Committe</h2>
        <div className="supercharge_items">
          <div className="item">
            <h4>Prof. Dr. Ir. Iwa Garniwa M.K., M.T. (ITPLN)</h4>
            <h4>Dipl.-ing. Iwan Tutuka Pambudi, M.Eng., Ph.d (ITPLN)</h4>
            <h4>Prof. Dr. Ir. Yusuf Latief, M.T (Universitas Indonesia)</h4>
            <h4>
              Prof. Dr. H. Aminullah Assagaf, S.e., M.S., M.M., M.Ak
              (Universitas Dr Soetomo)
            </h4>
            <h4>Prof. Ir. Rinaldy Dalimi, M.Sc., MM (Universitas Indonesia)</h4>
            <h4>
              Prof. Dr. Ir. Riri Fitri Sari, M.Sc., MM (Universitas Indonesia)
            </h4>
            <h4>Prof. Dr.-Ing. Mudrik Alaydrus (Universitas Mercu Buana)</h4>
          </div>

          <div className="item" style={{ paddingLeft: 30 }}>
            <h4>Prof. Dr. Ir Supriyanto, ST., M.Sc., IPM (UNTIRTA)</h4>
            <h4>Dr. Ir. Wahyuni Mariningsih, M.T. (UNTIRTA)</h4>
            <h4>Prof. Dr.-Ing. Andika Widya Pramono (BRIN)</h4>
            <h4>Dr.Eng. Amry Dasar, ST., M.Eng (UNSULBAR)</h4>
            <h4>
              Zico Alaia Akbar Junior, S.Si., M.Sc., Ph. D (Universitas
              Indonesia)
            </h4>
            <h4>Prof. Ir. Syamsir Abdul., MM, PhD (ITPLN)</h4>
            <h4>Prof. Sarwono Hardjomujadi Dr., Ir., M.T., M.H (ITPLN)</h4>
          </div>
        </div>
      </section>
    </>
  );
}

export default Committe;
