import React from "react";
import IMAGES from "../Assets/Images";
import { Row, Col } from "reactstrap";
function Sponsors() {
  return (
    <>
      <section className="supercharge" id="sponsors">
        <h3>Gold Sponsor</h3>
        <div className="supercharge_items" style={{ marginBottom: "20px" }}>
          <div className="sponsors-item-gold">
            <img
              src={IMAGES.puslitbang}
              alt="PLN Puslitbang"
              style={{ margin: 0 }}
            />
          </div>
        </div>
        <h3>Silver Sponsors</h3>
        <div className="supercharge_items" style={{ marginBottom: "20px" }}>
          <Col
            md="2"
            style={{
              width: "250px",
              height: "150px",
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "10px",
            }}
          >
            <img
              src={IMAGES.plnepi}
              alt="PLN EPI"
              className=""
              style={{
                position: "absolute",
                width: "100%",
                height: "auto",
                margin: 0,
              }}
            />
          </Col>
          <Col
            md="2"
            style={{
              width: "250px",
              height: "150px",
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "10px",
            }}
          >
            <img
              src={IMAGES.plnip}
              alt="PLN IP"
              className=""
              style={{
                position: "absolute",
                width: "100%",
                height: "auto",
                margin: 0,
              }}
            />
          </Col>
        </div>
        <h3>Supported By</h3>
        <div className="supercharge_items" style={{ paddingTop: "40px" }}>
          <Col
            md="2"
            style={{
              width: "100px",
              height: "100px",
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "10px",
            }}
          >
            <img
              src={IMAGES.itpln}
              alt="IT PLN"
              className=""
              style={{
                position: "absolute",
                width: "100%",
                height: "auto",
              }}
            />
          </Col>
          <Col
            md="2"
            style={{
              width: "100px",
              height: "100px",
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "10px",
            }}
          >
            <img
              src={IMAGES.ypk}
              alt="YPK PLN"
              className=""
              style={{
                position: "absolute",
                width: "100%",
                height: "auto",
              }}
            />
          </Col>

          <Col
            md="2"
            style={{
              width: "100px",
              height: "100px",
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "10px",
            }}
          >
            <img
              src={IMAGES.ujpk}
              alt="UJPK"
              className=""
              style={{
                position: "absolute",
                width: "100%",
                height: "auto",
              }}
            />
          </Col>
          <Col
            md="2"
            style={{
              width: "100px",
              height: "100px",
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "10px",
            }}
          >
            <img
              src={IMAGES.fj}
              alt="Firdha Jaya"
              className=""
              style={{
                position: "absolute",
                width: "100%",
                height: "auto",
              }}
            />
          </Col>
          <Col
            md="2"
            style={{
              width: "100px",
              height: "100px",
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "10px",
            }}
          >
            <img
              src={IMAGES.bni}
              alt="BNI"
              className=""
              style={{
                position: "absolute",
                width: "100%",
                height: "auto",
              }}
            />
          </Col>
          <Col
            md="2"
            style={{
              width: "100px",
              height: "100px",
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "10px",
            }}
          >
            <img
              src={IMAGES.kemdikbud}
              alt="Kemdikbud"
              className=""
              style={{
                position: "absolute",
                width: "100%",
                height: "auto",
              }}
            />
          </Col>
          <Col
            md="2"
            style={{
              width: "120px",
              height: "100px",
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "10px",
            }}
          >
            <img
              src={IMAGES.sinta}
              alt="Sinta"
              className=""
              style={{
                position: "absolute",
                width: "100%",
                height: "auto",
              }}
            />
          </Col>
          <Col
            md="2"
            style={{
              width: "120px",
              height: "100px",
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "10px",
            }}
          >
            <img
              src={IMAGES.googlesc}
              alt="Google"
              className=""
              style={{
                position: "absolute",
                width: "100%",
                height: "auto",
              }}
            />
          </Col>
        </div>
        <h3>Media Partner By</h3>
        <div className="supercharge_items" style={{ paddingTop: "10px" }}>
          <Col
            md="2"
            style={{
              width: "180px",
              height: "100px",
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "10px",
            }}
          >
            <img
              src={IMAGES.techno}
              alt="E-Techno"
              className=""
              style={{
                position: "absolute",
                width: "100%",
                height: "auto",
              }}
            />
          </Col>
          <Col
            md="2"
            style={{
              width: "200px",
              height: "100px",
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "10px",
            }}
          >
            <img
              src={IMAGES.itplnmedia}
              alt="IT PLN Media"
              className=""
              style={{
                position: "absolute",
                width: "100%",
                height: "auto",
              }}
            />
          </Col>
        </div>
      </section>
    </>
  );
}

export default Sponsors;
